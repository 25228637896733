/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p",
    img: "img",
    strong: "strong",
    em: "em",
    h3: "h3",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {HrefLangManager, TableOfContents, ButtonCta} = _components;
  if (!ButtonCta) _missingMdxReference("ButtonCta", true);
  if (!HrefLangManager) _missingMdxReference("HrefLangManager", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(HrefLangManager, {
    alternates: [['x-default', 'https://www.hear.com/hearing-aids/prices/'], ['en', 'https://www.hear.com/hearing-aids/prices/'], ['en-US', 'https://www.hear.com/hearing-aids/prices/'], ['en-IN', 'https://www.hear.com/in/hearing-aids/prices'], ['en-CA', 'https://ca.hear.com/hearing-aids/prices/']]
  }), "\n", React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "how-much-do-hearing-aids-cost",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-much-do-hearing-aids-cost",
    "aria-label": "how much do hearing aids cost permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How Much Do Hearing Aids Cost?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The cost of hearing aids depends on the level of technology you need to support your lifestyle. Also, just like your fingerprint, your hearing loss is unique, which is why you need a tailored solution. We at hear.com specialize in pairing you with hearing aids that are programmed to meet your specific needs. In addition, the prices you see on this page come with our Comprehensive Service Package, which includes the following:"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "✓ Personalized care from our hearing consultant and local Hearing Care Provider"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "✓ Up to five free hearing aid service appointments with our Hearing Care Provider"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "✓ Up to three years of warranty on all devices"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "✓ Free expert advice over the phone, email, and sms"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing aids are an important investment in your quality of life, so it’s crucial that you consider all your options carefully. Similar to buying a smartphone or TV, different brands and technology levels are available at a range of prices. Entry level devices are great if you live a sedentary lifestyle and have basic needs. However, you could really benefit from more advanced technology if you are socially active, professionally active, or frequent a variety of different environments."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "what-are-the-hearing-aid-technology-levels-and-price-ranges",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-are-the-hearing-aid-technology-levels-and-price-ranges",
    "aria-label": "what are the hearing aid technology levels and price ranges permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What Are the Hearing Aid Technology Levels and Price Ranges?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "There are three main hearing aid technology levels that meet the needs of those with mild, moderate, or severe hearing loss, all with different features and designs. The chart below provides a brief overview of the kind of technology that is present at each level with their respective price range. Whether it’s a premium, mid-range, or basic device, all our hearing aids provide excellent sound quality, optimal speech clarity, as well as feedback and background noise reduction."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/can-hearing-aid-prices.jpg",
    alt: "prices of hearing aids in Canada",
    className: "c-md-img"
  })), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "which-hearing-aid-technology-is-right-for-me",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#which-hearing-aid-technology-is-right-for-me",
    "aria-label": "which hearing aid technology is right for me permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Which Hearing Aid Technology Is Right for Me?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "There are two main factors to consider when thinking about hearing aid technology levels:"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "1. Your lifestyle:"), " if you work or socialize in noisy environments, you’ll need a more Premium device that provides excellent noise reduction and has directional microphones so you can hear and comprehend speech easily."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "2. Technology requirements:"), " if you love to have the latest, cutting-edge technology, then you’ll want to invest in Premium hearing aids that have more high-tech features, such as Bluetooth connectivity and smartphone App control."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The majority of our customers invest in either Mid-Range or Premium level devices, as they provide more advanced features and practical benefits to complement their lifestyle. To give you an accurate hearing aid estimate, it is necessary to learn more about your hearing situation, your individual needs, and personal preferences. We would be happy to speak with you and help you find hearing aids that fit your lifestyle and budget."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "which-hearing-aids-work-best-with-which-activities",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#which-hearing-aids-work-best-with-which-activities",
    "aria-label": "which hearing aids work best with which activities permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Which Hearing Aids Work Best with Which Activities?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/us_lifestyle_new-1.png",
    alt: "lifestyle",
    className: "c-md-img"
  }), "\n", React.createElement(_components.em, null, "No matter of how active you are - your hearing aid should blend in perfectly to your daily activities. Which hearing aid works best for you depends on your lifestyle.")), "\n", React.createElement(ButtonCta, {
    copy: "START NO-RISK TRIAL",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "more-about-hearing-aid-technology-levels",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#more-about-hearing-aid-technology-levels",
    "aria-label": "more about hearing aid technology levels permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "More About Hearing Aid Technology Levels"), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "premium-level-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#premium-level-hearing-aids",
    "aria-label": "premium level hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Premium Level Hearing Aids"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Premium hearing aids offer the best technology for your ears! They are equipped with more advanced versions of features found in the basic and mid-range levels, like automatic background noise reduction and advanced speech-processing algorithms. What does this mean for you? It means that you will be able to not only hear clearly but also understand every word – no matter where you are! So, if you spend a lot of time in noisy environments, premium technology is the best bang for your buck! On average, people spend $3,000 per hearing aid. However, what you get in return in terms of sound quality, speech clarity, and advanced features makes these devices a worthy investment. What should you expect from premium level technology? Let’s take a look."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "premium-level-technology-at-a-glance",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#premium-level-technology-at-a-glance",
    "aria-label": "premium level technology at a glance permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Premium Level Technology at a Glance"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Great for someone who is very active and outgoing"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Automatic noise suppression in all types of environments"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Offers the best speech clarity and most natural sound"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "360-degrees of sound"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Rechargeable"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Direct wireless streaming"), "\n"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "what-can-premium-level-technology-do-for-you",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-can-premium-level-technology-do-for-you",
    "aria-label": "what can premium level technology do for you permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What Can Premium Level Technology Do for You?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Premium technology gives you the ability to move around from place to place while still being able to hear clearly. Many of these devices are fully-automated and fully-featured, meaning they do all the work for you. When you enter a particular environment, your hearing aids analyze and adapt to your surroundings automatically. You can also manually program and save your favorite settings, so you get the best listening experience anywhere you go."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Surround sound for your ears")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing aids with the most advanced technology provide 360-degrees of sound. This means you don’t have to turn your head or position yourself to hear the person who is speaking. Another way to refer to this functionality is “binaural processing.” The word “binaural” refers to the use of both ears. In other words, the two hearing aids work together to make it easier to locate sounds. In situations where you have sounds and voices coming from all directions, binaural processing allows you to hear what’s most important (e.g. a speaker’s voice) while simultaneously reducing background noise."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Wireless streaming")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Premium level hearing aids add convenience to your life by connecting wirelessly to your electronic devices, either directly or through a pocket-sized accessory. Whether you want to hear your friend clearly while talking on your smartphone or watch your favorite TV show, you can wirelessly stream audio to your hearing aids. Now you can stay connected at all times and never miss a sound!"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Most major brands like Signia, Resound, Widex, and Starkey carry devices that are equipped with premium level technology. Make sure to ask one of our hearing consultants if premium level technology is right for you."), "\n", React.createElement(ButtonCta, {
    copy: "START NO-RISK TRIAL",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "mid-range-technology-level-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#mid-range-technology-level-hearing-aids",
    "aria-label": "mid range technology level hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Mid-Range Technology Level Hearing Aids"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "As the name implies, mid-range technology is right smack in the middle of basic and premium levels. Devices with this level of technology have all the features found in basic and a few from the premium level. In general, these devices provide good sound quality and speech clarity in most situations. In fact, many of our customers choose mid-range (and premium) devices, as they provide all the main features and benefits they are looking for in a hearing aid. Devices with mid-range technology cost between $1,999 – $2,699."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "mid-range-level-technology-at-a-glance",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#mid-range-level-technology-at-a-glance",
    "aria-label": "mid range level technology at a glance permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Mid-Range Level Technology at a Glance"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Great for someone who is active but also spends time in relatively quiet environments"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Automatic noise suppression in all types of environments"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Offers good speech clarity in complex listening situations"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Picks up sounds in certain directions and focuses on amplifying them"), "\n"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "what-can-mid-range-level-technology-do-for-you",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-can-mid-range-level-technology-do-for-you",
    "aria-label": "what can mid range level technology do for you permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What Can Mid-Range Level Technology Do for You?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you are someone who is moderately active, mid-range technology will provide the sound quality you need to live a happier and healthier life. Examples of places where you can benefit from wearing mid-range level devices include large auditoriums, family get-togethers, sporting events, parks, and restaurants. Just like premium level technology, mid-range devices can automatically suppress noise and deliver crystal-clear sound in most environments. You will be able to enjoy good speech clarity without asking people to frequently repeat themselves."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If mid-range technology sounds appealing, contact us to learn more about choosing the right device for you."), "\n", React.createElement(ButtonCta, {
    copy: "START NO-RISK TRIAL",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "basic-technology-level-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#basic-technology-level-hearing-aids",
    "aria-label": "basic technology level hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Basic Technology Level Hearing Aids"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "This level of technology is a good solution for individuals who spend a lot of time at home or in smaller, more intimate settings. So, if you enjoy having one-on-one chats, hearing aids with basic technology will make sure you can follow the conversation. You can expect good sound amplification and speech clarification, especially in quiet environments like libraries or at home. A basic hearing aid costs between $1,249 – $1,999."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "basic-level-technology-at-a-glance",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#basic-level-technology-at-a-glance",
    "aria-label": "basic level technology at a glance permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Basic Level Technology at a Glance"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Great for those who lead a sedentary lifestyle"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Good overall sound quality"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Feedback management"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Basic noise suppression"), "\n"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "what-can-basic-level-technology-do-for-you",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-can-basic-level-technology-do-for-you",
    "aria-label": "what can basic level technology do for you permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What Can Basic Level Technology Do for You?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Is your spouse complaining that you watch TV with the volume so high, even your neighbors can hear it? Or do you spend more time reading a person’s lips than actually listening to what he or she is saying? Then hearing aids with basic level technology may provide the performance you need to hear with very little effort. As a result, you will find more enjoyment in watching your favorite television programs and chatting with loved ones. In addition, basic devices do a good job of reducing occasional feedback and lowering background noise, so you can focus on what you want to hear."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Think you need a pair of basic hearing aids? Find out today if this technology level is right for you."), "\n", React.createElement(ButtonCta, {
    copy: "START NO-RISK TRIAL",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "the-hearcom-service",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#the-hearcom-service",
    "aria-label": "the hearcom service permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "The hear.com Service"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "At hear.com, we want everyone to hear well, so they can live well. Whether you’re young or old, we have a solution for every lifestyle and budget. We’ve changed over 100,000 lives by making the process of finding the right hearing aids easy and convenient. Thanks to our hearing success formula, we are the fastest growing hearing care company in the world and have a 92% hearing success rate!"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Comprehensive consultation")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "We will advise you on the latest audiological developments – in line with your personal preferences – while answering any questions related to hearing loss or your purchase. We will help you throughout the entire process, right up to the delivery of your new hearing aids."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "We work almost everywhere in Canada")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "With hear.com, you have access to over 200 Partner Providers all over Canada. We work exclusively with practices that have the highest customer satisfaction rating in the country!"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Test hearing aids completely risk-free")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "After you’ve been fitted with your new hearing aids, you will take home your new devices and begin your 90-day trial with a 100% money-back guarantee for the first 30-days. Test your hearing aids everywhere you go – at home with your family, at work with coworkers, or at the park with your grandchildren – and experience the difference in your everyday life."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Hearing aid fitting")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Our Partner Provider will use the latest scientific methods to make sure that your chosen hearing aids will meet your hearing needs and fit perfectly in your ears."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Two-sided consultation approach")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "You have dual protection – you have the support of your hear.com expert from your first consultation and the hearing care professional who will do your hearing aid fitting within your trial period."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Hearing evaluation to assess your hearing health")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Our Partner Provider conducts a hearing test based on strict medical criteria."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Included services after purchase")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "We will provide you with 3 years of warranty on your new hearing aids and 3 free service appointments within the next years so you can enjoy your hearing aids for a very long time."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Personal care")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Your hear.com hearing aid expert will stay in touch with you by phone and email. Our expert is available for a valuable second opinion and will help you as your personal guide."), "\n", React.createElement(ButtonCta, {
    copy: "START NO-RISK TRIAL",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
